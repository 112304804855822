.InovuaReactDataGrid__column-header {
  background: #1253fa !important;
  color: #fff !important;
}

.inovua-react-scroll-container__thumb {
  background: #b5b6b7 !important;
  margin: 0 auto;
  right: 0;
  left: 0;
}

.inovua-react-scroll-container__track {
  background: #e2dcdc;
}

.InovuaReactDataGrid__column-header__content {
  font-weight: bold !important;
  font-size: 12px !important;
}

.InovuaReactDataGrid__cell--group-cell:not(.InovuaReactDataGrid__cell--pivot-enabled) {
  font-weight: normal !important;
}

.InovuaReactDataGrid__footer-row-group {
  background: #d8d6d6 !important;
}

.InovuaReactDataGrid__column-header__filter-settings {
  pointer-events: none;
}

.InovuaReactDataGrid__column-header__menu-tool {
  display: none;
}
