@import './reactDataGrid.css';
@import './tostify.css';
@import './reactCustomScrollbars.css';

@font-face {
  font-family: 'Arimo-Regular';
  src: url(../assets/fonts/Arimo-Regular.ttf);
}

@font-face {
  font-family: 'Gilroy';
  src: url(../assets/fonts/Gilroy-Medium.otf);
}

@font-face {
  font-family: 'Gilroy Bold';
  src: url(../assets/fonts/Gilroy-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: 'Uni Sans SemiBold';
  src: url(../assets/fonts/Uni-Sans-Semibold.ttf);
}

@font-face {
  font-family: SFProText-Regular;
  src: url(../assets/fonts/SFProText-Regular.otf);
}

@font-face {
  font-family: SFProText-Semibold;
  src: url(../assets/fonts/SFProText-Semibold.otf);
}

@font-face {
  font-family: UniSans;
  src: url(../assets/fonts/Uni-Sans.otf);
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Gilroy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  color: #000;
  font-size: 14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.ellipsis {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.ReactModal__Body--open {
  overflow: hidden; /* prevent background scrolling */
}

.grecaptcha-badge {
  display: none;
}
