.ScrollbarsCustom-Track.ScrollbarsCustom-TrackY {
  background: transparent !important;
  top: 0 !important;
  height: 100% !important;
  padding: 3px;
  width: 12px !important;
}

.ScrollbarsCustom-Thumb.ScrollbarsCustom-ThumbY {
  background: #e1dcdb !important;
}
